import { Box, Button, Drawer, Grid, IconButton } from '@mui/material'
import Close from '@mui/icons-material/Close'
import { TimeSelector } from '../Selectors/timeSelector'
import {
  AllocationTypeSelector,
  AllocationTypeValue
} from '../Selectors/allocationTypeSelector'
import { SpecialitySelector } from '../Selectors/specialitySelector'
import { CustomerSelector } from '../Selectors/customerSelector'

interface EventsFilterDrawerProps {
  filter: {
    allocationType?: AllocationTypeValue
    customerId?: string
    from?: string
    specialityId?: string
    to?: string
    when?: string
  }
  onApply: () => void
  onChange: (filterName: string, value: any) => void
  onClose: () => void
  open: boolean
}

export const EventsFilterDrawer = ({
  filter,
  onApply,
  onChange,
  onClose,
  open
}: EventsFilterDrawerProps) => {
  return (
    <Drawer
      PaperProps={{
        sx: { width: '300px' }
      }}
      anchor={'right'}
      open={open}
      onClose={onClose}
    >
      <Box sx={theme => theme.mixins.toolbar} />
      <Box
        style={{ padding: '0px 8px' }}
        sx={{
          bgcolor: 'background.paper',
          boxShadow: 1
        }}
      >
        <Grid container>
          <Grid item xs={11}>
            <h4>Filtros</h4>
          </Grid>
          <Grid container direction={'row'} xs={1} justifyContent={'flex-end'}>
            <IconButton onClick={onClose}>
              <Close />
            </IconButton>
          </Grid>
        </Grid>
      </Box>
      <Grid container style={{ padding: '16px 8px' }}>
        <Grid item xs={12} style={{ marginBottom: '16px' }}>
          <TimeSelector
            when={filter.when}
            from={filter.from}
            to={filter.to}
            onChange={(filterName, value) => {
              onChange(filterName, value)
            }}
          />
        </Grid>
        <Grid item xs={12} style={{ marginBottom: '16px' }}>
          <AllocationTypeSelector
            value={filter.allocationType}
            onChange={(value: string) => onChange('allocationType', value)}
          />
        </Grid>
        <Grid item xs={12} style={{ marginBottom: '16px' }}>
          <SpecialitySelector
            value={filter.specialityId}
            onChange={(value: string) => onChange('specialityId', value)}
          />
        </Grid>
        <Grid item xs={12} style={{ marginBottom: '16px' }}>
          <CustomerSelector
            value={filter.customerId}
            onChange={(value: number) => onChange('customerId', value)}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Button variant="contained" onClick={onApply}>
          Aplicar
        </Button>
      </Grid>
    </Drawer>
  )
}
