import Big from 'big.js'

export const MonetaryValue = ({ value }: { value: Big }) => {
  const displayTotal = new Intl.NumberFormat('pt-PT', {
    style: 'currency',
    currency: 'EUR'
  }).format(value.abs().toNumber())

  return <>{displayTotal}</>
}
