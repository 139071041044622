import { createContext, useContext, useState } from 'react'
import { TimeSelectorValue } from '../../components/Selectors/timeSelector'
import { AllocationTypeValue } from '../../components/Selectors/allocationTypeSelector'

interface EventsState {
  filter: {
    customerId?: string
    allocationType?: AllocationTypeValue
    from?: string
    specialityId?: string
    to?: string
    when?: TimeSelectorValue
  }
  setFilter?: (property: string, value: any) => void
}

export const EventsContext = createContext<EventsState>({
  filter: {}
})

export const EventsContextProvider = ({ children }: any) => {
  const eventsContext = useContext(EventsContext)

  const [filter, setFilter] = useState<EventsState['filter']>({
    when: 'last-30-days'
  })

  const context = {
    ...eventsContext,
    filter,
    setFilter: (property: string, value: any) => {
      setFilter({
        ...filter,
        [property]: value
      })
    }
  }

  return (
    <EventsContext.Provider value={context}>{children}</EventsContext.Provider>
  )
}
