import React from 'react'
import { ListItem, ListItemIcon, ListItemText } from '@mui/material'
import { Link } from '../Link'

interface ListItemLinkProps {
  icon?: React.ReactElement
  primary: string
  to: string
}

export const ListItemLink = ({ icon, primary, to }: ListItemLinkProps) => {
  return (
    <ListItem button component={Link} to={to}>
      {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
      <ListItemText primary={primary} />
    </ListItem>
  )
}
