import { get } from './ApiService'

export const getReports = async (slug: string, filter: any) => {
  const reportsFilter = {
    ...filter,
    type: 'report'
  }

  return get<any[]>(
    `/${slug}/reports`,
    Object.keys(reportsFilter).length
      ? Object.fromEntries(
          Object.entries(reportsFilter).filter(
            ([_key, value]) => value !== undefined
          )
        )
      : undefined
  )
}

export const downloadReport = async (
  slug: string,
  customerId: string,
  fileId: number
) => {
  try {
    const result = await get<{ url: string }>(
      `/${slug}/reports/customer/${customerId}/report/${fileId}`
    )

    return result
  } catch (e) {
    return { url: null }
  }
}
