import { Report } from '@special-clinic/tutors-models'
import { Grid, useTheme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import ArticleIcon from '@mui/icons-material/Article'
import dayjs from 'dayjs'
import { useCallback } from 'react'
import { downloadReport } from '../../services/ReportsService'
import { useParams } from 'react-router-dom'

interface ReportListItemProps {
  customerId: string
  report: Report
}

const useStyles = makeStyles(() => ({
  label: {
    fontWeight: '200'
  },
  link: {
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline'
    }
  }
}))

export const Document = ({
  customerId,
  fileId,
  filename
}: {
  customerId: string
  fileId: number
  filename: string
}) => {
  const classes = useStyles()
  const { organizationSlug } = useParams()

  const openDocument = useCallback(async () => {
    if (!organizationSlug) {
      return
    }

    const { url } = await downloadReport(organizationSlug, customerId, fileId)

    if (!url) {
      return
    }

    window.open(url, '_blank')
  }, [])

  return (
    <Grid container direction={'row'} className={classes.link}>
      <ArticleIcon
        style={{
          fontSize: '1.2em',
          paddingRight: '2px'
        }}
      />
      <span className={`fs-body-1`} onClick={openDocument}>
        {'Download'}
      </span>
    </Grid>
  )
}

export const ReportListItem = ({ customerId, report }: ReportListItemProps) => {
  const theme = useTheme()

  return (
    <Grid
      container
      alignItems={'center'}
      style={{
        padding: '8px 16px',
        borderTop: `1px solid ${theme.palette.grey[300]}`,
        minHeight: '40px'
      }}
    >
      <Grid xs={3} item>
        {dayjs(report.createdAt).locale('pt').format('YYYY-MM-DD')}
      </Grid>
      <Grid xs={3} item>
        <span className="fs-body-1">{report.category}</span>
      </Grid>
      <Grid xs={4} item>
        <span className="fs-body-1">
          {report.therapist} - {report.speciality}
        </span>
      </Grid>
      <Grid xs={2} container>
        <Document
          customerId={customerId}
          fileId={report.id}
          filename={report.filename}
        />
      </Grid>
    </Grid>
  )
}
