import { useState } from 'react'
import {
  AppBar as MuiAppBar,
  IconButton,
  Toolbar,
  Typography
} from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import { UserMenu } from '../UserMenu'

interface AppBarProps {
  isNavBarOpen: boolean
  navBarWidth: number
  openNavBar: () => void
}

export const AppBar = ({
  isNavBarOpen,
  navBarWidth,
  openNavBar
}: AppBarProps) => {
  return (
    <MuiAppBar
      position="absolute"
      sx={theme => ({
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
          easing: theme.transitions.easing.sharp,
          duration: isNavBarOpen
            ? theme.transitions.duration.enteringScreen
            : theme.transitions.duration.leavingScreen
        }),
        ...(isNavBarOpen
          ? {
              marginLeft: navBarWidth,
              width: `calc(100% - ${navBarWidth}px)`
            }
          : {})
      })}
    >
      <Toolbar disableGutters={!isNavBarOpen} sx={{ paddingRight: '24px' }}>
        <IconButton
          color="inherit"
          aria-label="Open drawer"
          onClick={() => openNavBar()}
          sx={{
            marginLeft: '12px',
            marginRight: '36px',
            display: isNavBarOpen ? 'none' : undefined
          }}
        >
          <MenuIcon />
        </IconButton>
        <Typography
          component="h1"
          variant="h6"
          color="inherit"
          noWrap
          sx={{ flexGrow: 1 }}
        >
          Dashboard
        </Typography>
        <UserMenu />
      </Toolbar>
    </MuiAppBar>
  )
}
