import { CustomerBillingHistory } from '@special-clinic/tutors-models'
import { Box, Grid } from '@mui/material'
import PersonIcon from '@mui/icons-material/Person'
import { BillingListItem } from '../BillingListItem'

interface BillingListGroupProps {
  customer: CustomerBillingHistory['customer']
  transactions: CustomerBillingHistory['transactions']
}

export const BillingListGroup = ({
  customer,
  transactions
}: BillingListGroupProps) => {
  return (
    <Box
      sx={{
        bgcolor: 'background.paper',
        boxShadow: 1,
        minWidth: 300,
        borderRadius: '4px',
        marginBottom: '16px'
      }}
    >
      <Grid container style={{ padding: '8px 16px' }}>
        <PersonIcon style={{ fontSize: '1.50em', paddingRight: '6px' }} />
        <span className="fs-body-1">{customer.name}</span>
      </Grid>
      <Grid container>
        {(transactions || []).map((transaction, index) => (
          <BillingListItem
            key={`${transaction.id}-${index}`}
            customerId={customer.id}
            transaction={transaction}
          />
        ))}
      </Grid>
    </Box>
  )
}
