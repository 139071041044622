import { Box, Divider, Drawer, IconButton, List } from '@mui/material'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import HomeIcon from '@mui/icons-material/Home'
import EventsIcon from '@mui/icons-material/List'
import CalendarIcon from '@mui/icons-material/CalendarMonth'
import BillingIcon from '@mui/icons-material/AttachMoney'
import ReportsIcon from '@mui/icons-material/TextSnippet'
import { ListItemLink } from '../ListItemLink'
import { useTranslation } from 'react-i18next'

interface NavBarProps {
  close: () => void
  isOpen: boolean
  width: number
}

export const NavBar = ({ close, isOpen, width }: NavBarProps) => {
  const [t] = useTranslation()

  return (
    <Drawer
      variant="permanent"
      open={isOpen}
      PaperProps={{
        sx: theme => ({
          position: 'relative',
          whiteSpace: 'nowrap',
          width: width,
          transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
          }),
          ...(isOpen
            ? {}
            : {
                overflowX: 'hidden',
                transition: theme.transitions.create('width', {
                  easing: theme.transitions.easing.sharp,
                  duration: theme.transitions.duration.leavingScreen
                }),
                width: theme.spacing(7),
                [theme.breakpoints.up('sm')]: {
                  width: theme.spacing(7.5)
                }
              })
        })
      }}
    >
      <Box
        sx={theme => ({
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          padding: '0 8px',
          ...theme.mixins.toolbar
        })}
      >
        <IconButton onClick={close}>
          <ChevronLeftIcon />
        </IconButton>
      </Box>
      <Divider />
      <List>
        <ListItemLink icon={<HomeIcon />} primary={t('menu.home')} to={''} />
        <ListItemLink
          icon={<EventsIcon />}
          primary={t('menu.events')}
          to={'events'}
        />
        <ListItemLink
          icon={<CalendarIcon />}
          primary={t('menu.calendar')}
          to={'calendar'}
        />
        <ListItemLink
          icon={<BillingIcon />}
          primary={t('menu.billing')}
          to={'billing'}
        />
        <ListItemLink
          icon={<ReportsIcon />}
          primary={t('menu.reports')}
          to={'reports'}
        />
      </List>
    </Drawer>
  )
}
