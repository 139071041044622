import { BillingFilter } from '../hooks/providers/billing'
import { get } from './ApiService'
import { CustomerBillingHistory } from '@special-clinic/tutors-models'

interface GetBalancesResponse {
  id: number
  name: string
  balance: string
}

export const getBalances = async (slug: string) => {
  return get<GetBalancesResponse[]>(`/${slug}/balances`)
}

export const getBillingHistory = async (
  slug: string,
  filter: BillingFilter
) => {
  return get<CustomerBillingHistory[]>(
    `/${slug}/billing`,
    Object.keys(filter).length
      ? Object.fromEntries(
          Object.entries(filter).filter(([key, value]) => value !== undefined)
        )
      : undefined
  )
}
