import { FormControl, Autocomplete, TextField } from '@mui/material'

interface PaymentStatusSelectorProps {
  value?: string
  onChange: (value: string) => void
}

export const PaymentStatusSelector = ({
  value = '',
  onChange
}: PaymentStatusSelectorProps) => {
  const data = [
    { id: 'paid', name: 'Pago' },
    { id: 'not-paid', name: 'Por pagar' }
  ]
  return (
    <FormControl variant="standard" fullWidth>
      <Autocomplete
        options={data || []}
        getOptionLabel={option => option.name}
        value={(data || []).find(option => option.id === value) || null}
        onChange={(_, newValue) => {
          onChange(newValue?.id || '') // Update the value based on the selected option's ID
        }}
        renderInput={params => (
          <TextField {...params} label="Estado" variant="standard" />
        )}
      />
    </FormControl>
  )
}
