import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent
} from '@mui/material'
import { useCallback } from 'react'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DateField } from '@mui/x-date-pickers'
import dayjs from 'dayjs'

export type TimeSelectorValue =
  | 'this-week'
  | 'last-week'
  | 'this-month'
  | 'last-month'
  | 'this-semester'
  | 'last-semester'
  | 'last-30-days'
  | 'last-60-days'
  | 'last-90-days'
  | 'last-180-days'
  | 'custom'

interface TimeSelectorProps {
  when?: string
  from?: string
  to?: string
  onChange: (filterName: string, value: string) => void
}

const options = [
  { value: 'this-week', label: 'Esta semana' },
  { value: 'last-week', label: 'Semana passada' },
  { value: 'this-month', label: 'Neste mês' },
  { value: 'last-month', label: 'Último mês' },
  { value: 'this-semester', label: 'Neste semestre' },
  { value: 'last-semester', label: 'Último semestre' },
  { value: 'last-30-days', label: 'Últimos 30 dias' },
  { value: 'last-60-days', label: 'Últimos 60 dias' },
  { value: 'last-90-days', label: 'Últimos 90 dias' },
  { value: 'last-180-days', label: 'Últimos 180 dias' },
  { value: 'custom', label: 'Personalizado' }
]

export const TimeSelector = ({
  when,
  from,
  to,
  onChange
}: TimeSelectorProps) => {
  const handleWhenChange = useCallback(
    (event: SelectChangeEvent<string>) => {
      onChange('when', event.target.value)
    },
    [onChange]
  )
  const handleCustomDateChange = useCallback(
    (filterName: string, event: any) => {
      if (!event.isValid()) {
        return
      }

      const x = event.format('YYYY-MM-DD')
      console.log('date', x)
      onChange(filterName, event.format('YYYY-MM-DD'))
    },
    [onChange]
  )

  return (
    <>
      <FormControl variant="standard" fullWidth>
        <InputLabel>Quando</InputLabel>
        <Select value={when} label="Quando" onChange={handleWhenChange}>
          {options.map(({ value, label }) => (
            <MenuItem key={value} value={value}>
              {label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {when === 'custom' && (
        <FormControl variant="standard" fullWidth>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateField
              value={dayjs(from, 'YYYY-MM-DD')}
              format="DD-MM-YYYY"
              label="Data Inicio"
              variant="standard"
              onChange={event => handleCustomDateChange('from', event)}
            />
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateField
              format="DD-MM-YYYY"
              value={dayjs(to, 'YYYY-MM-DD')}
              label="Data Fim"
              variant="standard"
              onChange={event => handleCustomDateChange('to', event)}
            />
          </LocalizationProvider>
        </FormControl>
      )}
    </>
  )
}
