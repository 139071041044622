import { Event } from '@special-clinic/tutors-models'
import { Grid, Chip, useTheme } from '@mui/material'
import { useMemo } from 'react'

interface EventListItemProps {
  event: Event
}

export const EventListItem = ({ event }: EventListItemProps) => {
  const theme = useTheme()

  const statusFontColor = useMemo(() => {
    if (!event.status) {
      return theme.palette.warning.light
    }

    if (event.status === 'Presente') {
      return theme.palette.success.light
    }

    return theme.palette.error.light
  }, [event.status])

  const ownerBackgroundColor = useMemo(() => {
    if (event.owner.toLocaleLowerCase() === 'see') {
      return theme.palette.primary.light
    }

    return theme.palette.warning.light
  }, [event.owner])

  return (
    <Grid
      container
      alignItems={'center'}
      style={{
        padding: '8px 16px',
        borderTop: `1px solid ${theme.palette.grey[300]}`,
        minHeight: '40px'
      }}
    >
      <Grid xs={3} item>
        <span className="fs-body-1">{event.start}</span>
      </Grid>
      <Grid xs={2} item>
        <span className="fs-body-1">{event.type}</span>
      </Grid>
      <Grid xs={2} item>
        <span
          className="fs-body-1"
          style={{
            color: statusFontColor
          }}
        >
          {event.status || 'Não validado'}
        </span>
      </Grid>
      <Grid xs={3} item>
        <span className="fs-body-1">
          {event.therapist.name} - {event.speciality.acronym}
        </span>
      </Grid>
      <Grid xs={2} item>
        {event.owner !== 'Particular' && (
          <Chip
            label={event.owner}
            style={{
              backgroundColor: ownerBackgroundColor,
              color: '#fff',
              fontWeight: 'bold',
              fontSize: '0.75rem'
            }}
          />
        )}
      </Grid>
    </Grid>
  )
}
