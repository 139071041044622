import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import '@aws-amplify/ui-react/styles.css'
import { Home } from './pages/Home'
import { Events } from './pages/Events'
import { Calendar } from './pages/Calendar'
import { Billing } from './pages/Billing'
import { Reports } from './pages/Reports'
import { AccountSelector } from './pages/AccountSelector'
import { Layout } from './components/Layout'
import { EventsContextProvider } from './hooks/providers/events'
import { BillingContextProvider } from './hooks/providers/billing'
import {
  Authenticator,
  Theme,
  ThemeProvider,
  View,
  useTheme
} from '@aws-amplify/ui-react'
import { I18n } from 'aws-amplify/utils'
import { ReportsContextProvider } from './hooks/providers/reports'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false
    }
  }
})

I18n.putVocabulariesForLanguage('pt', {
  'Sign In': 'Entrar', // Tab header
  'Sign in': 'Entrar', // Button label
  Username: 'Email', // Username label
  Password: 'Password', // Password label
  'Enter your Email': 'Email',
  'Enter your Password': 'Password',
  'Incorrect username or password.': 'Dados de autenticação incorrectos',
  'Forgot your password?': 'Repor Password',
  'Change Password': 'Alterar password',
  'Confirm Password': 'Confirmar password',
  'Please confirm your Password': 'Confirmar password',
  'Signing in': 'Entrar',
  'Back to Sign In': 'Voltar'
})
I18n.setLanguage('pt')

const components = {
  Header() {
    const { tokens } = useTheme()

    return (
      <View textAlign="center" padding={tokens.space.large}>
        <img src={require('./assets/mr-logo.png')} />
      </View>
    )
  }
}

export const App = () => {
  const { tokens } = useTheme()
  const theme: Theme = {
    name: 'Authenticator',
    tokens: {
      components: {
        button: {
          primary: {
            backgroundColor: tokens.colors.blue['60']
          },
          link: {
            color: tokens.colors.blue['60']
          }
        },
        fieldcontrol: {
          _focus: {
            boxShadow: `0 0 0 2px ${tokens.colors.blue['60']}`
          }
        }
      }
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <Authenticator
        loginMechanisms={['email']}
        hideSignUp={true}
        components={components}
      >
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <Routes>
              <Route index path="/" element={<AccountSelector />} />
              <Route path="/:organizationSlug" element={<Layout />}>
                <Route path="/:organizationSlug" element={<Home />} />
                <Route
                  path="/:organizationSlug/events"
                  element={
                    <EventsContextProvider>
                      <Events />
                    </EventsContextProvider>
                  }
                />
                <Route
                  path="/:organizationSlug/calendar"
                  element={<Calendar />}
                />
                <Route
                  path="/:organizationSlug/billing"
                  element={
                    <BillingContextProvider>
                      <Billing />
                    </BillingContextProvider>
                  }
                />
                <Route
                  path="/:organizationSlug/reports"
                  element={
                    <ReportsContextProvider>
                      <Reports />
                    </ReportsContextProvider>
                  }
                />
              </Route>
            </Routes>
          </BrowserRouter>
        </QueryClientProvider>
      </Authenticator>
    </ThemeProvider>
  )
}
