import React from 'react'
import { FormControl, InputLabel, Autocomplete, TextField } from '@mui/material'
import { useCallback } from 'react'
import { useSpecialities } from '../../hooks/useSpecialities'
import { useParams } from 'react-router-dom'

interface SpecialitySelectorProps {
  value?: string
  onChange: (value: string) => void
}

export const SpecialitySelector = ({
  value = '',
  onChange
}: SpecialitySelectorProps) => {
  const { organizationSlug } = useParams()
  const { data } = useSpecialities(organizationSlug || '')

  return (
    <FormControl variant="standard" fullWidth>
      <Autocomplete
        options={data || []}
        getOptionLabel={option => option.name}
        value={(data || []).find(option => option.id === value) || null}
        onChange={(_, newValue) => {
          onChange(newValue?.id || '') // Update the value based on the selected option's ID
        }}
        renderInput={params => (
          <TextField {...params} label="Especialidade" variant="standard" />
        )}
      />
    </FormControl>
  )
}
