import { Big } from 'big.js'
import { Transaction } from '@special-clinic/tutors-models'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import ArticleIcon from '@mui/icons-material/Article'
import { Chip, Grid, useTheme } from '@mui/material'
import { makeStyles, createStyles } from '@mui/styles'
import { MonetaryValue } from '../MonetaryValue'
import { useCallback, useMemo } from 'react'
import dayjs from 'dayjs'
import 'dayjs/locale/pt'
import { getDocument } from '../../services/DocumentService'
import { useParams } from 'react-router-dom'

interface BillingListItemProps {
  customerId: string
  transaction: Transaction
}

interface Document {
  type: string
  id: string
}

const useStyles = makeStyles(() => ({
  label: {
    fontWeight: '200'
  },
  link: {
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline'
    }
  }
}))

export const Document = ({
  customerId,
  transaction
}: {
  customerId: string
  transaction: Transaction
}) => {
  const classes = useStyles()
  const { organizationSlug } = useParams()

  const openDocument = useCallback(
    async (
      documentType: 'invoice' | 'invoice-receipt' | 'receipt',
      documentId?: string
    ) => {
      if (!organizationSlug || !documentId) {
        return
      }
      const document = await getDocument(
        organizationSlug,
        customerId,
        documentType,
        documentId
      )
      window.open(document.file.url, '_blank')
    },
    [getDocument, organizationSlug]
  )

  if (Object.keys(transaction.documents).length === 0) {
    return (
      <Grid container direction={'row'}>
        <CloseIcon
          style={{
            fontSize: '1.2em',
            paddingRight: '2px'
          }}
        />
        <span className={`fs-body-1`}>{'Sem documento'}</span>
      </Grid>
    )
  }

  return (
    <>
      {transaction.documents.invoice && (
        <Grid container direction={'row'} className={classes.link}>
          <ArticleIcon
            style={{
              fontSize: '1.2em',
              paddingRight: '2px'
            }}
          />
          <span
            className={`fs-body-1`}
            onClick={() => {
              openDocument('invoice', transaction.documents.invoice)
            }}
          >
            {'Fatura'}
          </span>
        </Grid>
      )}
      {transaction.documents.invoiceReceipt && (
        <Grid container direction={'row'} className={classes.link}>
          <ArticleIcon
            style={{
              fontSize: '1.2em',
              paddingRight: '2px'
            }}
          />
          <span
            className={`fs-body-1`}
            onClick={() => {
              openDocument(
                'invoice-receipt',
                transaction.documents.invoiceReceipt
              )
            }}
          >
            {'Fatura-Recibo'}
          </span>
        </Grid>
      )}
      {Boolean(transaction.documents.receipts?.length) &&
        transaction.documents.receipts?.map((receipt: string) => {
          return (
            <Grid container direction={'row'} className={classes.link}>
              <ArticleIcon
                style={{
                  fontSize: '1.2em',
                  paddingRight: '2px'
                }}
              />
              <span
                className={`fs-body-1`}
                onClick={() => {
                  openDocument('receipt', receipt)
                }}
              >
                {'Recibo'}
              </span>
            </Grid>
          )
        })}
    </>
  )
}

export const MultibancoReference = ({
  transaction
}: {
  transaction: Transaction
}) => {
  const classes = useStyles()

  if (!transaction.multibanco) {
    return null
  }

  return (
    <>
      <Grid item>
        <span className={`fs-body-1 ${classes.label}`}>{'Entidate: '}</span>
        <span className={`fs-body-1`}>{transaction.multibanco?.entity}</span>
      </Grid>
      <Grid item>
        <span className={`fs-body-1 ${classes.label}`}>{'Referência: '}</span>
        <span className={`fs-body-1`}>{transaction.multibanco?.reference}</span>
      </Grid>
      <Grid item>
        <span className={`fs-body-1 ${classes.label}`}>{'Valor: '}</span>

        <span className={`fs-body-1`}>
          <MonetaryValue value={new Big(transaction.multibanco?.value)} />
        </span>
      </Grid>
    </>
  )
}

export const BillingListItem = ({
  customerId,
  transaction
}: BillingListItemProps) => {
  const theme = useTheme()
  const classes = useStyles()

  const ownerBackgroundColor = useMemo(() => {
    if (transaction.owner === 'see') {
      return theme.palette.primary.light
    }

    return theme.palette.warning.light
  }, [transaction.owner])

  const paymentStatus = useMemo(() => {
    if (transaction.paidPercentage === 0) {
      return {
        value: 'not-paid',
        label: 'Por pagar'
      }
    }

    if (transaction.paidPercentage === 100) {
      return {
        value: 'paid',
        label: 'Pago'
      }
    }

    return {
      value: 'partially-paid',
      label: 'Parcialmente pago'
    }
  }, [transaction.paidPercentage])

  const paymentStatusColor = useMemo(() => {
    if (paymentStatus.value === 'partially-paid') {
      return theme.palette.warning.light
    }

    if (paymentStatus.value === 'paid') {
      return theme.palette.success.light
    }

    return theme.palette.error.light
  }, [paymentStatus])

  return (
    <>
      {/* Debt */}
      <Grid
        container
        alignItems={'center'}
        style={{
          padding: '8px 16px',
          borderTop: `1px solid ${theme.palette.grey[300]}`,
          minHeight: '40px'
        }}
      >
        <Grid xs={2} item>
          <span className="fs-body-1">
            <MonetaryValue value={new Big(transaction.amount)} />
          </span>
        </Grid>
        <Grid xs={2} container direction={'column'}>
          <Grid item>
            <span
              className={`fs-body-1 ${classes.label}`}
            >{`Mês de serviço: `}</span>
            <span className="fs-body-1">
              {dayjs(transaction.month).locale('pt').format('MMMM YYYY')}
            </span>
          </Grid>
          <Grid item>
            <span
              className={`fs-body-1 ${classes.label}`}
            >{`Data de emissão: `}</span>
            <span className="fs-body-1">{transaction.date}</span>
          </Grid>
        </Grid>
        <Grid xs={1} item>
          <span className="fs-body-1">
            {transaction.owner !== 'customer' && (
              <Chip
                label={
                  transaction.owner.toLowerCase() === 'see'
                    ? 'SEE'
                    : transaction.insurer.name
                }
                style={{
                  backgroundColor: ownerBackgroundColor,
                  color: '#fff',
                  fontWeight: 'bold',
                  fontSize: '0.75rem'
                }}
              />
            )}
          </span>
        </Grid>
        <Grid xs={2} container>
          {paymentStatus.value === 'paid' ? (
            <CheckIcon
              style={{
                fontSize: '1.2em',
                paddingRight: '2px',
                color: paymentStatusColor
              }}
            />
          ) : (
            <CloseIcon
              style={{
                fontSize: '1.2em',
                paddingRight: '2px',
                color: paymentStatusColor
              }}
            />
          )}
          <span
            className="fs-body-1"
            style={{
              color: paymentStatusColor
            }}
          >
            {paymentStatus?.label}
          </span>
        </Grid>
        <Grid xs={2} container direction={'column'}>
          <MultibancoReference transaction={transaction} />
        </Grid>
        <Grid xs={2} container>
          <Document customerId={customerId} transaction={transaction} />
        </Grid>
      </Grid>
    </>
  )
}
