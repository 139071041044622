import { Box, Grid, IconButton } from '@mui/material'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import { useContext, useState } from 'react'
import { useReports } from '../../hooks/useReports'
import { useParams } from 'react-router-dom'
import { ReportsContext } from '../../hooks/providers/reports'
import { ReportListGroup } from '../../components/ReportListGroup'
import { ReportsFilterDrawer } from '../../components/ReportsFilterDrawer'

export const Reports = () => {
  const { organizationSlug } = useParams()
  const { setFilter, filter } = useContext(ReportsContext)

  const [filterDrawerOpen, setFilterDrawerOpen] = useState<boolean>(false)
  const { data, refetch } = useReports(organizationSlug || '', filter)

  return (
    <>
      <Box
        style={{ padding: '8px 16px' }}
        sx={{
          bgcolor: 'background.paper',
          boxShadow: 1
        }}
      >
        <Grid container>
          <Grid item xs={11}>
            <h3>Relatórios</h3>
          </Grid>
          <Grid container direction={'row'} xs={1} justifyContent={'flex-end'}>
            <IconButton onClick={() => setFilterDrawerOpen(true)}>
              <FilterAltIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Box>

      {data?.map((customerEvents, index) => (
        <ReportListGroup
          key={`${customerEvents.customer.name}-${index}`}
          reports={customerEvents.reports}
          customer={customerEvents.customer}
        />
      ))}

      <ReportsFilterDrawer
        filter={filter}
        onChange={(property, value) => {
          setFilter?.(property, value)
        }}
        open={filterDrawerOpen}
        onClose={() => setFilterDrawerOpen(false)}
        onApply={() => {
          refetch()
          setFilterDrawerOpen(false)
        }}
      />
    </>
  )
}
