import { createContext, useContext, useState } from 'react'

export interface BillingFilter {
  from?: string
  to?: string
  when?: string
  status?: string
  customerId?: string
}

interface BillingState {
  filter: BillingFilter
  setFilter?: (property: string, value?: string | number) => void
}

export const BillingContext = createContext<BillingState>({
  filter: {}
})

export const BillingContextProvider = ({ children }: any) => {
  const billingContext = useContext(BillingContext)

  const [filter, setFilter] = useState<BillingState['filter']>({
    when: 'last-30-days'
  })

  const context = {
    ...billingContext,
    filter,
    setFilter: (property: string, value?: string | number) => {
      setFilter({
        ...filter,
        [property]: value
      })
    }
  }

  return (
    <BillingContext.Provider value={context}>
      {children}
    </BillingContext.Provider>
  )
}
