import { get } from './ApiService'
import { Document } from '@special-clinic/tutors-models'

export const getDocument = async (
  slug: string,
  customerId: string,
  documentType: 'invoice' | 'invoice-receipt' | 'receipt',
  documentId: string
) => {
  return get<Document>(`/${slug}/customers/${customerId}/download`, {
    documentType,
    documentId
  })
}
