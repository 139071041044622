import React from 'react'
import { FormControl, InputLabel, Autocomplete, TextField } from '@mui/material'
import { useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { useCustomers } from '../../hooks/useCustomers'

interface CustomerSelectorProps {
  value?: string
  onChange: (value: number) => void
}

export const CustomerSelector = ({
  value = '',
  onChange
}: CustomerSelectorProps) => {
  const { organizationSlug } = useParams()
  const { data } = useCustomers(organizationSlug || '')
  const handleCustomerChange = useCallback(
    (value: number) => {
      onChange(value)
    },
    [onChange]
  )

  const options = (data || []).map(({ id, name }) => ({
    value: id,
    label: name
  }))

  return (
    <FormControl variant="standard" fullWidth>
      <Autocomplete
        options={options}
        getOptionLabel={option => option.label}
        value={options.find(option => option.value === value) || null}
        onChange={(_, option) => handleCustomerChange(option?.value)}
        renderInput={params => (
          <TextField {...params} label="Cliente" variant="standard" />
        )}
      />
    </FormControl>
  )
}
