import { getToken } from '../auth'

type Body = object | FormData
type Headers = Record<string, string>
type RequestOptions = {
  body?: Body
  headers?: Headers
}

const defaultOptions: RequestOptions = {
  headers: {
    Accept: 'application/json; charset=utf-8',
    'Content-Type': 'application/json; charset=utf-8'
  }
}

export const get = <T = any>(
  path: string,
  params: object | undefined = undefined,
  options: RequestOptions = defaultOptions
) => {
  let fullPath = path
  if (params) {
    fullPath += `?${new URLSearchParams(params as any).toString()}`
  }

  return request<T>('GET', fullPath, options)
}

const request = async <T = any>(
  method: string,
  path: string,
  { body, headers = {} }: RequestOptions = {}
) => {
  const authenticatedHeaders = await handleHeaders(headers)
  const response = await fetch(process.env.REACT_APP_API_URL + path, {
    body: handleBody(body),
    cache: 'no-cache',
    credentials: 'omit',
    headers: authenticatedHeaders,
    method,
    redirect: 'follow',
    referrer: 'no-referrer'
  })

  return (await handleResponse(response)) as T
}

const handleBody = (body: Body | undefined) => {
  if (!body) {
    return undefined
  }

  if (body instanceof FormData) {
    return body
  }

  return JSON.stringify(body)
}

const handleHeaders = async (headers: Headers | undefined) => {
  if (!headers) {
    headers = {}
  }

  const token = await getToken()
  if (token) {
    headers['Authorization'] = `Bearer ${token}`
  }

  return headers
}

const handleResponse = async (response: Response) => {
  let data: any
  if (response.headers.get('content-type')?.match(/application\/json/)) {
    data = await response.json()
  } else {
    data = await response.blob()
  }

  if (response.status === 401) {
    throw new Error('Unauthorized')
  }

  if (![200, 201].includes(response.status)) {
    throw new Error('ApiError')
  }

  return data
}
