import { Big } from 'big.js'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useCurrentBalances } from '../../../hooks/useCurrentBalance'
import { Box, Skeleton } from '@mui/material'
import { MonetaryValue } from '../../../components/MonetaryValue'

const Loading = () => {
  return (
    <>
      <Skeleton variant="text" />
      <Skeleton variant="text" />
    </>
  )
}

const Error = () => {
  const [t] = useTranslation()
  return (
    <>
      <Box sx={{ color: 'text.secondary' }}>{t('totalBalanceCard.error')}</Box>
      <Box sx={{ color: 'text.primary', fontSize: 34, fontWeight: 'medium' }}>
        --
      </Box>
    </>
  )
}

const ValueTitle = ({ total }: { total: Big }) => {
  const [t] = useTranslation()

  if (total.lt(Big(0))) {
    return <>{t('totalBalanceCard.debtTitle')}</>
  } else if (total.gt(Big(0))) {
    return <>{t('totalBalanceCard.creditTitle')}</>
  } else {
    return <>{t('totalBalanceCard.zeroTitle')}</>
  }
}

const Value = ({ total }: { total: Big }) => {
  return (
    <>
      <Box sx={{ color: 'text.secondary' }}>
        <ValueTitle total={total} />
      </Box>
      <Box sx={{ color: 'text.primary', fontSize: 34, fontWeight: 'medium' }}>
        <MonetaryValue value={total} />
      </Box>
    </>
  )
}

export const TotalBalanceCard = () => {
  const { organizationSlug } = useParams()
  const { data: balances, isLoading, isError } = useCurrentBalances(
    organizationSlug || ''
  )

  const total = balances?.reduce(
    (total, item) => total.add(new Big(item.balance)),
    new Big(0)
  )

  return (
    <Box
      sx={{
        bgcolor: 'background.paper',
        boxShadow: 1,
        borderRadius: 2,
        p: 2,
        minWidth: 300
      }}
    >
      {isLoading ? (
        <Loading />
      ) : isError ? (
        <Error />
      ) : (
        <Value total={total || Big(0)} />
      )}
    </Box>
  )
}
