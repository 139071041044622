import { useState } from 'react'
import { Box, createTheme, CssBaseline, ThemeProvider } from '@mui/material'
import { AppBar } from '../AppBar'
import { NavBar } from '../NavBar'
import { Outlet } from 'react-router-dom'

const navBarWidth = 240

const theme = createTheme({
  palette: {
    background: {
      default: '#eaedf3',
      paper: '#fff'
    },
    text: {
      primary: '#173A5E',
      secondary: '#46505A'
    }
  }
})

export const Layout = () => {
  const [isNavBarOpen, setNavBarOpen] = useState(false)

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar
          isNavBarOpen={isNavBarOpen}
          navBarWidth={navBarWidth}
          openNavBar={() => setNavBarOpen(true)}
        />
        <NavBar
          close={() => setNavBarOpen(false)}
          isOpen={isNavBarOpen}
          width={navBarWidth}
        />
        <Box
          sx={{
            flexGrow: 1,
            padding: 3,
            height: '100vh',
            overflow: 'auto'
          }}
        >
          <Box sx={theme => theme.mixins.toolbar} />
          <Outlet />
        </Box>
      </Box>
    </ThemeProvider>
  )
}
