import { fetchAuthSession } from 'aws-amplify/auth'

export const getToken = async (storage = sessionStorage) => {
  try {
    const { accessToken } = (await fetchAuthSession()).tokens ?? {}

    return accessToken?.toString()
  } catch (err) {
    console.log(err)
  }
}
