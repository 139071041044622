import { useState } from 'react'
import { Divider, IconButton, Link, Menu, MenuItem } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { signOut } from 'aws-amplify/auth'
import { Link as RouterLink } from '../Link'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import { profileUrl, securityUrl } from '../../constants/account'

export const UserMenu = () => {
  const [t] = useTranslation()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div>
      <IconButton
        aria-controls="menu-appbar"
        aria-haspopup="true"
        aria-label="account of current user"
        color="inherit"
        onClick={handleMenu}
        size="large"
      >
        <AccountCircleIcon />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          component={Link}
          onClick={() => {
            signOut()
          }}
        >
          {t('userMenu.logout')}
        </MenuItem>
      </Menu>
    </div>
  )
}
