export const pt = {
  accountSelector: {
    errorTitle: 'Oops',
    errorDescription:
      'Não conseguimos carregar as informações da sua conta. Por favor, tente novamente.',
    errorRetry: 'Tentar novamente'
  },
  menu: {
    billing: 'Faturação',
    calendar: 'Calendário',
    events: 'Eventos',
    home: 'Página inicial',
    reports: 'Relatórios'
  },
  userMenu: {
    changeAccount: 'Trocar conta',
    changePassword: 'Alterar palavra-passe',
    logout: 'Terminar sessão',
    profile: 'Meus dados'
  },
  totalBalanceCard: {
    debtTitle: 'Total em dívida',
    creditTitle: 'Saldo',
    zeroTitle: 'Saldo',
    error: 'Erro ao carregar os dados'
  }
}
