import { createContext, useContext, useState } from 'react'
import { TimeSelectorValue } from '../../components/Selectors/timeSelector'

export interface ReportsFilter {
  customerId?: string
  from?: string
  specialityId?: string
  to?: string
  when?: TimeSelectorValue
}

interface ReportsState {
  filter: ReportsFilter
  setFilter?: (property: string, value?: string | number) => void
}

export const ReportsContext = createContext<ReportsState>({
  filter: {}
})

export const ReportsContextProvider = ({ children }: any) => {
  const reportsContext = useContext(ReportsContext)

  const [filter, setFilter] = useState<ReportsState['filter']>({
    when: 'last-30-days'
  })

  const context = {
    ...reportsContext,
    filter,
    setFilter: (property: string, value?: string | number) => {
      setFilter({
        ...filter,
        [property]: value
      })
    }
  }

  return (
    <ReportsContext.Provider value={context}>
      {children}
    </ReportsContext.Provider>
  )
}
