import ReactDOM from 'react-dom/client'
import { Amplify } from 'aws-amplify'
import './index.css'
import { App } from './App'
import reportWebVitals from './reportWebVitals'
import './i18n'

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID as string,
      userPoolClientId: process.env
        .REACT_APP_COGNITO_USER_POOL_CLIENT_ID as string
    }
  }
})

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(<App />)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
