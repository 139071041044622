import { Event } from '@special-clinic/tutors-models'
import { Box, Grid } from '@mui/material'
import { Customer } from '@special-clinic/models'
import PersonIcon from '@mui/icons-material/Person'
import { EventListItem } from '../EventListItem'

interface EventListGroupProps {
  customer: Customer
  events: Event[]
}

export const EventListGroup = ({ customer, events }: EventListGroupProps) => {
  return (
    <Box
      sx={{
        bgcolor: 'background.paper',
        boxShadow: 1,
        minWidth: 300,
        borderRadius: '4px',
        marginBottom: '16px'
      }}
    >
      <Grid container style={{ padding: '8px 16px' }}>
        <PersonIcon style={{ fontSize: '1.50em', paddingRight: '6px' }} />
        <span className="fs-body-1">{customer.name}</span>
      </Grid>
      <Grid container>
        {(events || []).map((event, index) => (
          <EventListItem key={`${event.start}-${index}`} event={event} />
        ))}
      </Grid>
    </Box>
  )
}
