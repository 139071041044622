import { useEffect, useState } from 'react'
import {
  CircularProgress,
  Link,
  List,
  Paper,
  Box,
  Alert,
  AlertTitle,
  Button
} from '@mui/material'
import { useMyOrganizations } from '../../hooks/useMyOrganizations'
import { ListItemLink } from '../../components/ListItemLink'
import { useTranslation } from 'react-i18next'
import { logoutUrl } from '../../constants/account'
import { useNavigate } from 'react-router-dom'
import DomainIcon from '@mui/icons-material/Domain'
import { Organization } from '../../types/Organization'

const OrganizationList = ({
  organizations
}: {
  organizations: Organization[]
}) => {
  return (
    <Paper sx={{ width: '100%', maxWidth: '360px' }}>
      <List>
        {organizations.map((organization, index) => (
          <ListItemLink
            icon={<DomainIcon />}
            key={index}
            primary={organization.name}
            to={`/${organization.slug}`}
          />
        ))}
      </List>
    </Paper>
  )
}

const Loading = () => {
  return (
    <Box
      sx={{
        color: 'primary.contrastText',
        padding: 2,
        textAlign: 'center'
      }}
    >
      <CircularProgress color="inherit" />
    </Box>
  )
}

const Error = () => {
  const [t] = useTranslation()
  const navigate = useNavigate()

  return (
    <Alert severity="error" sx={{ width: '100%', maxWidth: '360px' }}>
      <AlertTitle>{t('accountSelector.errorTitle')}</AlertTitle>
      {t('accountSelector.errorDescription')}
      <Box sx={{ marginTop: 2 }}>
        <Button
          variant="outlined"
          color="error"
          onClick={() => {
            navigate(0)
          }}
        >
          {t('accountSelector.errorRetry')}
        </Button>
      </Box>
    </Alert>
  )
}

export const AccountSelector = () => {
  const [t] = useTranslation()
  const navigate = useNavigate()
  const [isReady, setReady] = useState(false)
  const { data: organizations, isLoading, isError } = useMyOrganizations()

  useEffect(() => {
    if (organizations?.length === 1) {
      navigate(`/${organizations?.[0].slug}`)
    } else if (organizations) {
      setReady(true)
    }
  }, [organizations, navigate])

  return (
    <Box
      sx={{
        alignItems: 'center',
        backgroundColor: 'primary.main',
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        justifyContent: 'center'
      }}
    >
      {isError ? (
        <Error />
      ) : isLoading || !isReady ? (
        <Loading />
      ) : (
        <OrganizationList organizations={organizations || []} />
      )}
      <Box sx={theme => ({ marginTop: theme.spacing(2) })}>
        <Link sx={{ color: 'primary.contrastText' }} href={logoutUrl}>
          {t('userMenu.logout')}
        </Link>
      </Box>
    </Box>
  )
}
