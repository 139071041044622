import { en } from './en'
import { pt } from './pt'

export const resources = {
  en: {
    translation: en
  },
  pt: {
    translation: pt
  }
}
