import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import dayjs from 'dayjs'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import ptLocale from '@fullcalendar/core/locales/pt'
import { useEvents } from '../../hooks/useEvents'

interface CalendarEvent {
  title: string
  date: string
}

const TWO_YEARS_BEFORE = dayjs().subtract(2, 'year').format('YYYY-MM-DD')
const TWO_YEARS_AHEAD = dayjs().add(2, 'year').format('YYYY-MM-DD')

export const Calendar = () => {
  const { organizationSlug } = useParams()
  const { data } = useEvents(organizationSlug || '', {
    when: 'custom',
    from: TWO_YEARS_BEFORE,
    to: TWO_YEARS_AHEAD
  })
  const [events, setEvents] = useState<CalendarEvent[]>([])

  useEffect(() => {
    if (data) {
      const events: CalendarEvent[] = []
      data.forEach(customerEvents => {
        customerEvents.events.forEach((event: any) => {
          events.push({
            title: `${customerEvents.customer.name} || (${event.speciality.acronym}) ${event.therapist.name}`,
            date: event.start
          })
        })
      })
      setEvents(events)
    }
  }, [data])

  return (
    <>
      <FullCalendar
        headerToolbar={{
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay'
        }}
        plugins={[dayGridPlugin, timeGridPlugin]}
        initialView="timeGridWeek"
        locales={[ptLocale]}
        locale={'pt'}
        events={events}
        slotDuration={'00:15:00'}
        businessHours={false}
        nowIndicator={true}
        slotMinTime="08:00:00"
        slotMaxTime="21:00:00"
        validRange={{
          start: TWO_YEARS_BEFORE,
          end: TWO_YEARS_AHEAD
        }}
      />
    </>
  )
}
