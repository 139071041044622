import React, { SyntheticEvent } from 'react'
import { FormControl, InputLabel, Autocomplete, TextField } from '@mui/material'
import { useCallback } from 'react'

export type AllocationTypeValue = 'see' | 'private' | null

interface AllocationTypeSelectorProps {
  value?: AllocationTypeValue
  onChange: (value: string) => void
}

const options = [
  { value: 'see', label: 'See' },
  { value: 'private', label: 'Particular' }
]

export const AllocationTypeSelector = ({
  value,
  onChange
}: AllocationTypeSelectorProps) => {
  const handleChange = useCallback(
    (value: string) => {
      return onChange(value)
    },
    [onChange]
  )

  return (
    <FormControl variant="standard" fullWidth>
      <Autocomplete
        options={options}
        getOptionLabel={option => option.label}
        value={options.find(option => option.value === value) || null}
        onChange={(_, option) => handleChange(option?.value || '')}
        renderInput={params => (
          <TextField {...params} label="Tipo de cliente" variant="standard" />
        )}
      />
    </FormControl>
  )
}
