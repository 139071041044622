export const en = {
  accountSelector: {
    errorTitle: 'Oops',
    errorDescription:
      "We couldn't load the information from your account. Please try again.",
    errorRetry: 'Retry'
  },
  menu: {
    home: 'Home',
    events: 'Events',
    calendar: 'Calendar',
    billing: 'Billing',
    reports: 'Reports'
  },
  userMenu: {
    changeAccount: 'Change account',
    profile: 'Profile',
    changePassword: 'Change password',
    logout: 'Logout'
  },
  totalBalanceCard: {
    debtTitle: 'Debt total',
    creditTitle: 'Balance',
    zeroTitle: 'Balance',
    error: 'Error while loading the data'
  }
}
