import Grid from '@mui/material/Unstable_Grid2/Grid2'
import { TotalBalanceCard } from './TotalBalanceCard'

export const Home = () => {
  return (
    <Grid container spacing={2}>
      <Grid xs={12} sm={8} md={3}>
        <TotalBalanceCard />
      </Grid>
    </Grid>
  )
}
